<template>
  <CrossgridHeader :contained-width="containedWidth" :custom-user="customUser" />
  <main>
    <section>
      <div class="flex flex-col lg:flex-row">
        <div :class="size" class="hidden bg-primary dark:bg-primary-dark lg:flex">
          <slot name="sidebar"></slot>
        </div>
        <div class="grow bg-tertiary dark:bg-secondary-dark lg:basis-full">
          <main>
            <div>
              <slot name="main"></slot>
            </div>
          </main>
        </div>
        <div class="fixed bottom-0 w-full bg-primary pb-7 dark:bg-primary-dark lg:hidden">
          <slot name="sidebar"></slot>
        </div>
      </div>
    </section>
  </main>
  <div class="hidden bg-primary dark:bg-primary-dark lg:block">
    <CrossgridFooter />
  </div>
</template>

<script lang="ts" setup>
import {computed} from 'vue';

import {MinimalUser} from '@/types/user';

import CrossgridFooter from '@/components/partials/CrossgridFooter.vue';
import CrossgridHeader from '@/components/partials/CrossgridHeader.vue';

type Props = {
  sidebarSize?: 'basis-1/2' | 'basis-1/3' | 'basis-1/4' | 'basis-0';
  customUser?: MinimalUser;
  containedWidth?: boolean;
  sidebar?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  sidebarSize: 'basis-1/4',
  customUser: undefined,
  containedWidth: false,
});

const size = computed(() => {
  return `lg:${props.sidebarSize}`;
});
</script>
