<template>
  <div v-if="errorMessages" class="col-span-full" data-testid="error-message">
    <div class="rounded-md bg-error-primary p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <XCircleIcon class="h-5 w-5 text-error-primary" aria-hidden="true" />
        </div>
        <div v-if="errorMessages.length > 1" class="ml-3">
          <h3 class="text-sm font-medium text-error-primary">Some things went wrong..</h3>
          <ul class="mt-2 list-disc">
            <li
              v-for="(message, index) in errorMessages"
              :key="index"
              class="text-sm font-medium text-error-primary">
              {{ message }}
            </li>
          </ul>
          <div v-if="errorType === 'unexpected'" class="mt-4 text-sm text-error-primary">
            <p>Contact {{ CROSSGRID_SUPPORT.phone }} for help.</p>
          </div>
        </div>
        <div v-else class="ml-3">
          <h3 class="text-sm font-medium text-error-primary">{{ errorMessages[0] }}</h3>
          <div v-if="errorType === 'unexpected'" class="mt-4 text-sm text-error-primary">
            <p>Contact {{ CROSSGRID_SUPPORT.phone }} for help.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, ref} from 'vue';
import {XCircleIcon} from '@heroicons/vue/20/solid';

import {CROSSGRID_SUPPORT} from '@/constants/common';
import {APIStandardError} from '@/types/network';

type Props = {
  errors?: APIStandardError;
};

const props = withDefaults(defineProps<Props>(), {
  errors: undefined,
});

const errorType = ref('general');

const errorMessages = computed(() => {
  if (props.errors) {
    let allErrors: string[] = [];

    // Handle general errors
    const generalErrorMessages = props.errors?.validation_error
      ?.filter(error => error.id === '__all__')
      .map(error => error.msg);
    if (generalErrorMessages) allErrors = allErrors.concat(generalErrorMessages);

    // Handle unexpected errors
    const unexpectedErrorMessage = props.errors?.unexpected_error;
    if (unexpectedErrorMessage) {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      errorType.value = 'unexpected';
      allErrors.push(unexpectedErrorMessage);
    }

    return allErrors.length < 1 ? undefined : allErrors;
  }
  return undefined;
});
</script>
