<template>
  <CrossgridAuthLayout>
    <template #main>
      <CrossgridCard
        title="Create your free account"
        description=""
        :logo="true"
        :signup-flow="true"
        size="sm:max-w-md"
        :errors="errors"
        :action-button-disabled="actionButtonDisabled"
        :is-loading="isLoading"
        @cancel-click="handleNavigation('back')"
        @action-click="handleNavigation('next')">
        <template #main>
          <div class="col-span-full">
            <CrossgridLabel id="email" label="Email" />
            <CrossgridInput
              id="email"
              v-model="formData.email"
              :data-testid="'email'"
              type="text"
              autocomplete="email"
              placeholder="Enter your email"
              :errors="errors" />
          </div>

          <div class="col-span-full">
            <CrossgridLabel id="password1" label="Password" />
            <CrossgridInput
              id="password1"
              v-model="formData.password1"
              :data-testid="'password'"
              type="password"
              autocomplete="new-password"
              placeholder="Create a password"
              :errors="errors" />
          </div>

          <div class="col-span-full">
            <div class="flex items-center">
              <div class="flex items-center">
                <input
                  id="terms-conditions"
                  v-model="termsConditions"
                  :data-testid="'terms-conditions'"
                  aria-describedby="item-conditions-description"
                  name="terms-conditions"
                  type="checkbox"
                  class="h-4 w-4 rounded border-primary bg-white text-brand-solid focus:ring-brand-solid dark:border-primary-dark dark:bg-primary-dark dark:text-brand-solid-dark dark:focus:ring-brand-solid-dark" />
              </div>
              <div class="ml-2 text-sm">
                <label for="terms-conditions" class="text-tertiary dark:text-tertiary-dark"
                  >I agree to Crossgrid's
                  <a
                    class="text-brand-primary"
                    :href="CROSSGRID_PAGE_URLS.external.terms_of_service"
                    target="_blank"
                    >Terms & Conditions</a
                  ></label
                >
              </div>
            </div>
          </div>
        </template>
      </CrossgridCard>
    </template>
  </CrossgridAuthLayout>
</template>

<script setup lang="ts">
import {computed, onBeforeMount, reactive, ref, Ref} from 'vue';
import {useUserStore} from '@/stores/userStore';
import {useRouter} from 'vue-router';

import {CROSSGRID_PAGE_URLS} from '@/constants/common';
import {APIStandardError} from '@/types/network';
import {UserSignupSavedProgress, UserSignupStepTwo} from '@/types/user-signup';
import {useHttp} from '@/utils/composables';
import {addHiddenFieldAPIErrors} from '@/utils/shared';
import {getUserSignupSavedProgress} from '@/utils/user-signup';

import CrossgridAuthLayout from '@/components/_layout/CrossgridAuthLayout.vue';
import CrossgridCard from '@/components/atoms/CrossgridCard.vue';
import CrossgridInput from '@/components/atoms/CrossgridInput.vue';
import CrossgridLabel from '@/components/atoms/CrossgridLabel.vue';

const router = useRouter();
const http = useHttp();

const formData = reactive<UserSignupStepTwo>({} as UserSignupStepTwo);
const errors: Ref<APIStandardError | undefined> = ref(undefined);
const termsConditions = ref<boolean>(false);
const isLoading = ref<boolean>(false);

const userSignupProgress = ref<UserSignupSavedProgress | null>(getUserSignupSavedProgress());

const userStore = useUserStore();

const createAccount = () => {
  http
    .post('/user/user_signup/create_account/', formData)
    .then(() => {
      userStore.fetchUserSession();
      localStorage.removeItem('user_signup_progress');

      const aid = localStorage.getItem('aid');
      if (aid) {
        http
          .post('/affiliate/event/', {
            affiliate_id: aid,
            event_type: 'user_signup',
          })
          .catch(() => {});
      }

      return router.push(CROSSGRID_PAGE_URLS.user.signup.success);
    })
    .catch(error => {
      errors.value = addHiddenFieldAPIErrors(
        error.response?.data,
        errors.value,
        ['email', 'password1'],
        true
      );
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const handleNavigation = (action: string) => {
  if (action === 'back') return router.push(CROSSGRID_PAGE_URLS.user.signup.step_one);

  if (userSignupProgress.value?.step_one) {
    Object.assign(formData, userSignupProgress.value.step_one);
    isLoading.value = true;

    const path = router.currentRoute.value.path;
    const url = `${window.location.origin}${path}`;
    formData.page_url = url;

    return createAccount();
  }
};

const actionButtonDisabled = computed(() => {
  return (
    Object.values(formData).length < 2 ||
    Object.values(formData).some(value => value === '') ||
    !termsConditions.value
  );
});

onBeforeMount(() => {
  if (!userSignupProgress.value?.step_one)
    return router.push(CROSSGRID_PAGE_URLS.user.signup.step_one);
});
</script>
