<template>
  <CrossgridHeader :contained-width="true" />
  <section class="relative">
    <div
      :style="bgImage(`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/crossgrid-icon.svg`)"
      class="absolute top-0 hidden h-full w-full bg-[length:45px_42px] bg-repeat md:block"></div>
    <div
      class="absolute h-full w-full bg-secondary bg-opacity-[85%] dark:bg-secondary-dark dark:bg-opacity-[98%] md:bg-opacity-[97%]"></div>
    <div class="mx-auto h-[620px] max-w-7xl lg:h-[720px] lg:w-full">
      <div class="md:hidden">
        <div
          :style="bgImage(`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/4.jpg`)"
          class="absolute top-0 h-full w-full bg-cover bg-center bg-no-repeat md:hidden"></div>
        <div
          class="absolute left-0 top-0 h-full w-full bg-secondary opacity-[90%] dark:bg-secondary-dark"></div>
      </div>

      <div class="h-full lg:flex">
        <div class="relative pt-20 md:w-1/2 md:px-5 lg:pt-36">
          <h1
            class="ml-10 text-left font-['Inter'] text-5xl font-medium text-primary dark:text-primary-dark md:mr-0 md:text-right lg:text-6xl">
            <div class="mb-5">Find</div>

            <span class="bg-brand-primary-dark px-4 font-bold text-primary-on-brand"
              >Christian</span
            >
            <br />
            <div class="mt-5">Businesses</div>
          </h1>
          <div
            class="mt-24 space-y-2 bg-primary p-8 shadow-2xl dark:border-primary-dark dark:bg-secondary-dark dark:text-primary-dark md:mt-10 md:rounded-2xl md:border-2 md:px-10 lg:absolute lg:left-[calc(50%-10px)] lg:z-10 lg:w-full">
            <form class="flex flex-col gap-2" @submit.prevent="handleSubmitSearch">
              <div>
                <CrossgridSearch
                  v-model="selectedService"
                  :pro-services="proServices"
                  :placeholder="'What services are you looking for?'"
                  @search-query="(query: string) => handleSearchQuery(query)" />
              </div>

              <div>
                <CrossgridButton
                  :is-loading="isLoading"
                  label="Search"
                  type="submit"
                  name="search"
                  size="md" />
              </div>
            </form>
            <p id="business-description" class="text-xs text-tertiary dark:text-tertiary-dark">
              Try searching Electrician, House Cleaning, or Painter
            </p>
          </div>
        </div>

        <div
          :style="bgImage(`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/3.jpg`)"
          class="absolute right-0 top-0 hidden h-full w-1/2 bg-cover bg-center shadow md:block lg:hidden"></div>

        <div class="scroll-container">
          <div class="scroll-content">
            <div>
              <div
                v-for="(image, index) in duplicatedLeftColumnImages"
                :key="index"
                class="scroll-image-set">
                <div :style="bgImage(image.url)" :class="image.class"></div>
              </div>
            </div>
            <div>
              <div
                v-for="(image, index) in duplicatedRightColumnImages"
                :key="index"
                class="scroll-image-set">
                <div :style="bgImage(image.url)" :class="image.class"></div>
              </div>
            </div>
          </div>

          <div
            class="absolute top-0 hidden h-1/4 w-[480px] bg-gradient-to-t from-transparent to-white/50 dark:to-gray-950/50 lg:block"></div>
          <div
            class="absolute bottom-0 hidden h-1/4 w-[480px] bg-gradient-to-t from-white/50 to-transparent dark:from-gray-950/50 lg:block"></div>
        </div>
      </div>

      <button
        class="absolute bottom-[-75px] left-1/2 -translate-x-1/2 transform rounded-full bg-primary/20 p-4 shadow-lg backdrop-blur-md md:bottom-8 md:left-1/4 lg:bottom-10 lg:left-[calc(50%-10px)]"
        @click="scrollToSection('service')">
        <ArrowDownIcon class="arrow-down h-8 w-8 text-tertiary-dark" />
      </button>
    </div>
  </section>
  <section id="service" class="relative">
    <div class="my-20 py-10">
      <h2 class="px-10 pb-10 text-center text-5xl font-medium text-primary dark:text-primary-dark">
        Experience <span class="text-brand-700">excellent</span> service
      </h2>
      <div
        class="flex max-w-7xl flex-col items-center justify-center gap-12 px-5 md:flex-row lg:mx-auto">
        <div class="relative w-full md:w-1/2 lg:w-[450px]">
          <div
            :style="bgImage(`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/service.jpg`)"
            class="h-[450px] rounded-[28px] bg-cover bg-center"></div>
        </div>
        <div class="w-full md:w-1/2 lg:w-[450px]">
          <h3 class="text-3xl font-light text-primary dark:text-primary-dark">
            What makes Crossgrid different?
          </h3>
          <h4 class="mt-2 text-xl font-light text-tertiary dark:text-tertiary-dark">
            We vet our pros to ensure every customer has a great experience
          </h4>
          <ul class="mt-10 space-y-5 font-medium text-primary dark:text-primary-dark">
            <li class="flex items-center gap-2">
              <CheckIcon class="h-5 w-5 text-brand-700" aria-hidden="true" />
              <p>Dedicated teams that are faith-based</p>
            </li>
            <li class="flex items-center gap-2">
              <CheckIcon class="h-5 w-5 text-brand-700" />
              <p>Strict vetting process for new pro listings</p>
            </li>
            <li class="flex items-center gap-2">
              <CheckIcon class="h-5 w-5 text-brand-700" />
              <p>Strong emphasis on customer satisfaction</p>
            </li>
            <li class="flex items-center gap-2">
              <CheckIcon class="h-5 w-5 text-brand-700" />
              <p>Excellent experience throughout transaction</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section class="relative">
    <div
      :style="bgImage(`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/crossgrid-icon.svg`)"
      class="absolute top-0 h-[500px] w-full bg-[length:45px_42px]"></div>
    <div class="relative h-[500px] w-full bg-brand-700 bg-opacity-[97%]"></div>
    <div
      class="absolute top-0 flex h-full w-full flex-col items-center justify-center space-y-8 px-5 text-center font-light text-white">
      <h2 class="max-w-[800px] text-5xl font-bold text-white">Ready to start your project?</h2>
      <h3 class="max-w-[600px] text-2xl">
        Get matched with businesses or customers today and get your project going.
      </h3>
      <div class="flex gap-4 pt-12">
        <RouterLink
          :to="{
            name: CROSSGRID_PAGE_URLS.pro_service.select.step_one,
            params: {source: 'user_signup'},
          }"
          class="text-md rounded-lg border-2 border-white bg-primary px-5 py-2 font-medium text-brand-primary hover:bg-tertiary-hover">
          Find a Pro
        </RouterLink>
        <RouterLink
          :to="{
            name: CROSSGRID_PAGE_URLS.pro_service.select.step_one,
            params: {source: 'pro_signup'},
          }"
          class="text-md rounded-lg border-2 border-white px-5 py-2 font-medium text-white hover:bg-brand-500">
          Find Customers
        </RouterLink>
      </div>
    </div>
  </section>
  <section id="pricing" class="relative">
    <div class="mx-auto max-w-5xl pt-10">
      <div class="mx-auto flex max-w-5xl flex-col px-10 pb-5 text-primary dark:text-primary-dark">
        <h2 class="mt-12 text-3xl font-bold md:text-4xl">How it works</h2>
        <h3 class="mt-3 text-xl font-light text-secondary dark:text-secondary-dark">
          For Customers:
        </h3>
      </div>

      <div
        class="mx-auto flex max-w-5xl flex-col justify-between text-center text-xl font-light text-secondary dark:text-secondary-dark lg:flex-row">
        <div
          class="flex flex-col items-center gap-3 p-12 md:flex-row lg:w-1/3 lg:shrink-0 lg:flex-col">
          <img
            :src="`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/marketing/browse.svg`"
            class="mx-auto shrink-0 sm:h-72 sm:w-72" />
          <p class="mx-auto max-w-sm py-10">
            Search our wide range of services that meet your need
          </p>
        </div>
        <div
          class="flex flex-col items-center gap-3 p-12 md:flex-row-reverse lg:w-1/3 lg:shrink-0 lg:flex-col">
          <img
            :src="`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/marketing/referral.svg`"
            class="mx-auto shrink-0 sm:h-72 sm:w-72" />
          <p class="mx-auto max-w-sm py-10">
            Get matched with faith-based professionals in your area
          </p>
        </div>
        <div
          class="flex flex-col items-center gap-3 p-12 md:flex-row lg:w-1/3 lg:shrink-0 lg:flex-col">
          <img
            :src="`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/marketing/complete.svg`"
            class="mx-auto shrink-0 sm:h-72 sm:w-72" />
          <p class="mx-auto max-w-sm py-10">No extra work required, 100% free for customers</p>
        </div>
      </div>
      <div class="flex justify-center gap-4">
        <RouterLink
          :to="{
            name: CROSSGRID_PAGE_URLS.pro_service.select.step_one,
            params: {source: 'user_signup'},
          }"
          class="text-md rounded-lg bg-brand-700 px-5 py-2 font-medium text-white hover:bg-brand-800">
          Find a Pro
        </RouterLink>
      </div>
    </div>
    <div class="pt-10">
      <div class="mx-auto flex max-w-5xl flex-col px-10 pb-5 text-primary dark:text-primary-dark">
        <h3 class="mt-3 text-xl font-light text-secondary dark:text-secondary-dark">For Pros:</h3>
      </div>
      <div
        class="mx-auto flex max-w-5xl flex-col justify-between text-center text-xl font-light text-secondary dark:text-secondary-dark lg:flex-row">
        <div
          class="flex flex-col items-center gap-3 p-12 md:flex-row lg:w-1/3 lg:shrink-0 lg:flex-col">
          <img
            :src="`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/marketing/search.svg`"
            class="mx-auto shrink-0 sm:h-72 sm:w-72" />
          <p class="mx-auto max-w-sm py-10">
            Customers come to the platform looking for your services
          </p>
        </div>
        <div
          class="flex flex-col items-center gap-3 p-12 md:flex-row-reverse lg:w-1/3 lg:shrink-0 lg:flex-col">
          <img
            :src="`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/marketing/analysis.svg`"
            class="mx-auto shrink-0 sm:h-72 sm:w-72" />
          <p class="mx-auto max-w-sm py-10">
            We ensure you're a good fit for the job and the customer is serious
          </p>
        </div>
        <div
          class="flex flex-col items-center gap-3 p-12 md:flex-row lg:w-1/3 lg:shrink-0 lg:flex-col">
          <img
            :src="`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/marketing/success.svg`"
            class="mx-auto shrink-0 sm:h-72 sm:w-72" />
          <p class="mx-auto max-w-sm py-10">
            You pay once we determine it's a quality lead and you're a good fit for the job
          </p>
        </div>
      </div>
      <div class="flex justify-center gap-4">
        <RouterLink
          :to="{
            name: CROSSGRID_PAGE_URLS.pro_service.select.step_one,
            params: {source: 'pro_signup'},
          }"
          class="text-md rounded-lg bg-brand-700 px-5 py-2 font-medium text-white hover:bg-brand-800">
          Find Customers
        </RouterLink>
      </div>
    </div>
  </section>
  <section class="relative">
    <div class="mx-auto flex max-w-4xl flex-col items-center gap-5 py-16 md:flex-row md:px-5">
      <div
        :style="bgImage(`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/fam.jpg`)"
        class="h-[448px] w-full bg-cover bg-center shadow md:w-1/2 md:rounded-[28px]"></div>

      <div class="w-full max-w-xl space-y-8 px-5 md:w-1/2">
        <h3 class="mt-10 text-4xl font-medium text-primary dark:text-primary-dark md:mt-0">
          "Crossgrid got me connected with a great plumber who was very knowledgeable and was great
          to work with"
        </h3>
        <p class="font-light text-secondary dark:text-secondary-dark">Jack Smith, Long Island NY</p>
        <div class="flex gap-4 pt-12">
          <RouterLink
            :to="{
              name: CROSSGRID_PAGE_URLS.pro_service.select.step_one,
              params: {source: 'user_signup'},
            }"
            class="text-md rounded-lg border-2 border-brand-700 bg-brand-700 px-5 py-2 font-medium text-white hover:border-brand-800 hover:bg-brand-800">
            Find a Pro
          </RouterLink>
          <RouterLink
            :to="{
              name: CROSSGRID_PAGE_URLS.pro_service.select.step_one,
              params: {source: 'pro_signup'},
            }"
            class="text-md rounded-lg border-2 border-secondary bg-primary px-5 py-2 font-medium text-primary hover:bg-secondary dark:border-primary-dark dark:bg-primary-dark dark:text-primary-dark">
            Find Customers
          </RouterLink>
        </div>
      </div>
    </div>
  </section>
  <section id="about">
    <div class="mx-auto px-5 pb-16 text-primary dark:text-primary-dark">
      <div
        class="flex items-center justify-center space-x-4 text-4xl font-medium text-primary dark:text-primary-dark md:text-5xl">
        <img :src="`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/crossgrid-icon.svg`" class="h-10 w-10" />
        <h2 class="py-10">About Crossgrid</h2>
      </div>
      <div class="md:px-5 lg:px-10">
        <div class="flex items-center justify-center gap-2">
          <div class="flex max-w-sm flex-col items-center space-y-5 py-10 md:w-1/2 md:pr-10">
            <h3 class="w-full text-center text-2xl font-medium md:text-left">
              Bridging Faith and Business
            </h3>
            <p class="w-full md:text-sm">
              Welcome to Crossgrid, where we connect communities through faith and service. Our app
              is designed to support the Christian community by linking customers with the
              professional services they are looking for, be it plumbing or piano lessons. While our
              focus is on the Christian community, we believe that Jesus' love covers every corner
              of this earth, and we welcome ALL users on our platform.
            </p>
            <h3 class="w-full text-center text-2xl font-medium md:text-left">Our Story</h3>
            <p class="w-full md:text-sm">
              Our journey began with a simple observation: there is a significant disconnect between
              Christians and business. Many of us attend church every Sunday, sitting next to people
              whose professional skills and services remain unknown to us. In our broader
              communities, finding and supporting fellow Christians in business is often a
              challenge.
            </p>
          </div>

          <div class="hidden md:block">
            <div
              :style="bgImage(`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/about.jpg`)"
              class="h-[448px] w-[333px] rounded-[28px] bg-cover bg-center shadow"></div>
          </div>
        </div>
        <div class="mx-auto max-w-sm space-y-5 md:max-w-3xl md:px-2 md:px-7">
          <h3 class="w-full text-center text-2xl font-medium md:text-left">Our Mission</h3>
          <p class="w-full md:text-sm">
            We aim to close the gap between faith and business by creating the largest network of
            Christian business owners and bringing them the customers they seek. Our mission is
            twofold:
          </p>
          <ol class="list-decimal px-5 md:text-sm">
            <li>
              <span class="font-bold">Support and Growth:</span> By fostering connections within the
              Christian community, we help believers support each other professionally and
              personally.
            </li>
            <li>
              <span class="font-bold">Evangelism Through Excellence:</span> Through the quality and
              integrity of the services provided by our Pros, we hope to make a positive impact on
              the non-Christian community. We believe that exceptional service grounded in faith can
              serve as a powerful form of evangelism.
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
  <CrossgridFooter :contained-width="true" />
</template>
<script setup lang="ts">
import {onMounted, ref, Ref} from 'vue';
import {ArrowDownIcon, CheckIcon} from '@heroicons/vue/20/solid';
import {RouterLink, useRouter} from 'vue-router';

import {CROSSGRID_IMAGE_HOSTING_BASE_URL, CROSSGRID_PAGE_URLS} from '@/constants/common';
import {ProService} from '@/types/pro-services';
import {MinimalUser} from '@/types/user';
import {searchProService} from '@/utils/pro-services-search';
import {getSession} from '@/utils/shared';

import CrossgridButton from '@/components/atoms/CrossgridButton.vue';
import CrossgridSearch from '@/components/molecules/CrossgridSearch.vue';
import CrossgridFooter from '@/components/partials/CrossgridFooter.vue';
import CrossgridHeader from '@/components/partials/CrossgridHeader.vue';

const selectedService: Ref<ProService | null> = ref(null);
const proServices: Ref<ProService[]> = ref([]);

const userSession: Ref<MinimalUser | null> = ref(null);
const isLoading: Ref<boolean> = ref(false);

const imageUrls = [
  `${CROSSGRID_IMAGE_HOSTING_BASE_URL}/1.jpg`,
  `${CROSSGRID_IMAGE_HOSTING_BASE_URL}/2.jpg`,
  `${CROSSGRID_IMAGE_HOSTING_BASE_URL}/3.jpg`,
  `${CROSSGRID_IMAGE_HOSTING_BASE_URL}/4.jpg`,
  `${CROSSGRID_IMAGE_HOSTING_BASE_URL}/5.jpg`,
  `${CROSSGRID_IMAGE_HOSTING_BASE_URL}/6.jpg`,
  `${CROSSGRID_IMAGE_HOSTING_BASE_URL}/7.jpg`,
  `${CROSSGRID_IMAGE_HOSTING_BASE_URL}/8.jpg`,
];

const createImageSets = (urls: string[], filterFn: (index: number) => boolean) => {
  return urls
    .filter((_, index) => filterFn(index))
    .map(url => ({
      url,
      class: 'mt-[10px] bg-cover bg-center shadow lg:h-[298px] lg:w-[233px] lg:rounded-[28px]',
    }));
};

const leftColumnImages = createImageSets(imageUrls, index => index % 2 === 0);
const rightColumnImages = createImageSets(imageUrls, index => index % 2 !== 0);

const duplicatedLeftColumnImages = ref([...leftColumnImages, ...leftColumnImages]);
const duplicatedRightColumnImages = ref([...rightColumnImages, ...rightColumnImages]);

const bgImage = (url: string) => {
  return {
    backgroundImage: `url(${url})`,
  };
};

const router = useRouter();

const handleSearchQuery = (searchQuery: string) => {
  if (searchQuery === '') selectedService.value = null;
  searchProService(searchQuery).then(services => {
    proServices.value = services;
  });
};

const scrollToSection = (section: string) => {
  const element = document.getElementById(section);
  if (element) {
    element.scrollIntoView({behavior: 'smooth'});
  }
};

const handleSubmitSearch = async () => {
  if (!selectedService.value) return;

  isLoading.value = true;

  const userAuthenticated = userSession.value?.is_authenticated;
  const source = userAuthenticated ? 'user_job_search' : 'user_signup';
  const service = selectedService.value;

  // Handle case where pro service is not in standard list
  if (!service.slug) {
    localStorage.setItem(
      'pro_service_select_progress',
      JSON.stringify({
        service_custom: service,
      })
    );

    const routeName = userAuthenticated
      ? CROSSGRID_PAGE_URLS.user.job_search.step_one
      : CROSSGRID_PAGE_URLS.user.signup.step_one;

    return router.push({
      name: routeName,
    });
  }

  // Standard pro service case
  const progress = {
    step_one: {pro_service: service.slug, source},
  };

  localStorage.setItem('pro_service_select_progress', JSON.stringify(progress));

  router.push({
    name: CROSSGRID_PAGE_URLS.pro_service.select.step_two,
    params: {slug: service.slug, source},
  });

  isLoading.value = false;
};

onMounted(() => {
  getSession().then(session => (userSession.value = session));
  localStorage.removeItem('job_search_progress');
  localStorage.removeItem('pro_service_select_progress');
});
</script>

<style scoped>
* {
  scroll-behavior: smooth;
}

.scroll-container {
  overflow: hidden;
  position: relative;
  height: 720px; /* Fixed height for the container */
}

.scroll-content {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  animation: scrollVertical 60s linear infinite; /* Adjust the duration as needed */
}

@keyframes scrollVertical {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-50%)); /* Adjust the translateY value to account for the gap */
  }
}

.scroll-image-set {
  display: contents; /* Ensures that the grid layout is applied to the children */
}

.scroll-content > div:nth-child(2n + 1) {
  transform: translateY(-149px); /* Offset by 50% of the image height */
}
</style>
