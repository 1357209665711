import {ChatBubbleLeftIcon} from '@heroicons/vue/24/solid';

import {CROSSGRID_PAGE_URLS} from '@/constants/common';
import {SidebarItem} from '@/types/ui-elements';

export const USER_SIDEBAR_ITEMS: SidebarItem[] = [
  {
    displayName: 'Messages',
    routeName: CROSSGRID_PAGE_URLS.user.dashboard.messages.root,
    defaultRoute: CROSSGRID_PAGE_URLS.user.dashboard.messages.inbox,
    icon: ChatBubbleLeftIcon,
  },
];
