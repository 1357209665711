<template>
  <div class="relative">
    <component
      :is="htmlTag"
      :id="id"
      :type="type"
      :name="id"
      :placeholder="placeholder"
      :value="modelValue"
      :autocomplete="autocomplete"
      :disabled="disabled"
      v-bind="{...properties, ...$attrs}"
      :class="[
        direction === 'right' ? 'rounded-r-none' : direction === 'left' ? 'rounded-l-none' : '',
        'block w-full rounded-md border-0 bg-primary py-1.5 text-primary shadow-sm ring-1 ring-inset placeholder:text-placeholder focus:ring-2 focus:ring-inset disabled:bg-disabled disabled:text-disabled dark:bg-primary-dark dark:text-primary-dark dark:placeholder:text-placeholder dark:disabled:bg-disabled-dark dark:disabled:text-disabled-dark sm:text-sm sm:leading-6',
        isIconDefined ? 'ps-9' : '',
        errorMessages
          ? 'ring-error focus:ring-error dark:ring-error-dark'
          : 'ring-primary focus:ring-brand-solid dark:ring-primary-dark',
        icon !== undefined ? 'ps-9' : '',
      ]"
      @input="handleInput" />

    <component
      :is="icon"
      v-if="isIconDefined"
      class="pointer-events-none absolute inset-y-0 start-0 m-auto flex h-7 w-7 items-center ps-3 dark:text-primary-dark" />

    <div
      v-if="errorMessages"
      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
      <ExclamationCircleIcon class="h-5 w-5 text-error-secondary" aria-hidden="true" />
    </div>
  </div>

  <p
    v-for="(message, index) in errorMessages"
    :id="`${id}_error`"
    :key="index"
    class="mt-1.5 text-xs text-error-primary dark:text-error-primary-dark">
    {{ message }}
  </p>

  <p
    v-if="helpText && !errorMessages"
    :id="`${id}_description`"
    class="mt-1.5 text-xs text-tertiary dark:text-tertiary-dark">
    {{ helpText }}
  </p>
</template>

<script setup lang="ts">
import {computed, FunctionalComponent, toRefs} from 'vue';
import {ExclamationCircleIcon} from '@heroicons/vue/24/outline';

import {APIStandardError} from '@/types/network';

type Props = {
  type?: 'text' | 'email' | 'multiline' | 'number' | 'hidden' | 'password' | 'search';
  placeholder?: string;
  id?: string;
  modelValue?: string;
  helpText?: string;
  autocomplete?: string;
  rows?: string;
  errors?: APIStandardError;
  disabled?: boolean;
  icon?: FunctionalComponent | undefined;
  value?: string;
  direction?: 'default' | 'left' | 'right';
};

const props = defineProps<Props>();

const {type, rows, id, modelValue, helpText, autocomplete, errors, disabled, icon, direction} =
  toRefs(props);

const isIconDefined = computed(() => {
  return typeof icon.value === 'function';
});

const isMultiline = computed(() => {
  return type.value === 'multiline';
});

const htmlTag = computed(() => {
  return isMultiline.value ? 'textarea' : 'input';
});

const errorMessages = computed(() => {
  if (errors.value) {
    const fieldErrors = errors.value?.validation_error
      ?.filter(error => error.id === id.value)
      .map(error => error.msg);
    return fieldErrors && fieldErrors.length > 0 ? fieldErrors : undefined;
  }
  return undefined;
});

const properties = computed(() => {
  return isMultiline.value ? {rows: rows.value} : {};
});

const handleInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  emit('update:modelValue', target.value);
};

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();
</script>
