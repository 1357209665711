<template>
  <div
    data-testid="file-input"
    :class="[
      isDragging
        ? 'border-2 border-solid border-brand-solid bg-brand-primary dark:border-brand-solid-dark dark:bg-brand-primary-dark'
        : errorMessage || clientError
          ? 'border-2 border-solid border-error dark:border-error-dark'
          : 'border-dashed border-primary bg-secondary dark:border-primary-dark dark:bg-secondary-dark',
    ]"
    class="mt-2 flex justify-center rounded-lg border px-4 py-4"
    @dragover="handleDragOver"
    @dragleave="() => (isDragging = false)"
    @drop="handleDrop">
    <div class="text-center">
      <div v-if="previewImage">
        <img :src="previewImage.toString()" />
        <button
          type="button"
          class="focus-visible:outline-error-solid mt-4 w-full rounded-md bg-error-solid px-2.5 py-1.5 text-sm font-semibold text-primary-fg shadow-sm ring-1 ring-inset ring-error-solid hover:bg-error-solid-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          @click="handleClearImage">
          Remove image
        </button>
      </div>
      <div v-else class="cursor-pointer py-4" @click="handleSelectFile">
        <PhotoIcon
          class="mx-auto h-12 w-12 text-tertiary dark:text-tertiary-dark"
          aria-hidden="true" />
        <div class="mt-4 text-sm leading-6 text-tertiary dark:text-tertiary-dark">
          <input
            :id="id"
            ref="fileInput"
            :name="id"
            type="file"
            class="sr-only"
            @input="pickFile" />
          <p v-if="isDragging" class="text-primary-on-brand">Release to drop your image!</p>
          <p v-else>
            <span class="font-semibold text-brand-secondary dark:text-brand-secondary-dark"
              >Click to upload</span
            >
            or drag and drop
          </p>
        </div>
        <p
          :class="[
            isDragging ? 'dark:text-primary-on-brand' : 'text-tertiary dark:text-tertiary-dark',
          ]"
          class="text-xs leading-5">
          PNG, JPG or GIF up to 5MB
        </p>
        <p
          :class="[
            isDragging ? 'dark:text-primary-on-brand' : 'text-tertiary dark:text-tertiary-dark',
          ]"
          class="mt-2 text-xs leading-5">
          Minimum size: 500 X 500 pixels
        </p>
      </div>
    </div>
  </div>
  <p
    v-if="errorMessage"
    :id="`${id}_error`"
    class="mt-1.5 text-xs text-error-primary dark:text-error-primary-dark">
    {{ errorMessage }}
  </p>
  <p v-if="clientError" class="mt-1.5 text-xs text-error-primary dark:text-error-primary-dark">
    {{ clientError }}
  </p>
</template>

<script setup lang="ts">
import {computed, ref, watchEffect} from 'vue';
import {PhotoIcon} from '@heroicons/vue/20/solid';

import {APIStandardError} from '@/types/network';
import {ImageValidationRules} from '@/types/pro-profile';

const file = ref<File | null>(null);
const fileInput = ref<HTMLInputElement | null>(null);
const previewImage = ref<string | ArrayBuffer | null>(null);
const isDragging = ref(false);
const clientError = ref<string>('');

type Props = {
  id?: string;
  fileValue?: File | null;
  errors?: APIStandardError;
  image_validation_rules: ImageValidationRules;
};

const props = withDefaults(defineProps<Props>(), {
  id: undefined,
  fileValue: null,
  errors: undefined,
  image_validation_rules: Object as () => ImageValidationRules,
});

const emits = defineEmits<{
  (e: 'update:fileValue', value: File | null): void;
}>();

const pickFile = () => {
  clientError.value = '';
  const input = fileInput.value;
  if (input && input.files?.length) {
    handleFileProcessing(input.files[0]);
  }
};

const handleSelectFile = () => {
  if (fileInput.value) return fileInput.value.click();
};

const handleFileProcessing = (file: File | null) => {
  if (file) {
    if (file.size >= props.image_validation_rules.file_size.max_size_bytes)
      return (clientError.value = props.image_validation_rules.file_size.error_message);
    if (!props.image_validation_rules.image_type.allowed_types_mime_format.includes(file.type))
      return (clientError.value = props.image_validation_rules.image_type.error_message);
    const reader = new FileReader();
    reader.onload = e => {
      const img = new Image();
      img.onload = () => {
        if (
          img.naturalWidth < props.image_validation_rules.pixel_size.min_width ||
          img.naturalHeight < props.image_validation_rules.pixel_size.min_height
        ) {
          return (clientError.value = props.image_validation_rules.pixel_size.error_message);
        }
        previewImage.value = e.target?.result ?? null;
      };
      img.src = e.target?.result as string;
    };
    reader.readAsDataURL(file);
    emits('update:fileValue', file);
  }
};

const handleDrop = (event: DragEvent) => {
  event.preventDefault();
  isDragging.value = false;
  const droppedFiles = event.dataTransfer?.files;
  if (droppedFiles && droppedFiles.length) {
    file.value = droppedFiles[0];
    handleFileProcessing(droppedFiles[0]);
  }
};

const handleClearImage = () => {
  previewImage.value = null;
  file.value = null;
  emits('update:fileValue', null);
};

const handleDragOver = (e: DragEvent) => {
  e.preventDefault();
  isDragging.value = true;
};

const errorMessage = computed(() => {
  if (props.errors) {
    const error = props.errors.validation_error?.find(error => error.id === props.id);
    return error ? error.msg : null;
  }
  return null;
});

watchEffect(() => {
  if (props.fileValue) {
    file.value = props.fileValue;
    handleFileProcessing(props.fileValue);
  }
});
</script>
