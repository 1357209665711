<template>
  <CrossgridAuthLayout>
    <template #main>
      <CrossgridCard
        title="🎉 Welcome to Crossgrid 🎉"
        description=""
        :cancel-button="null"
        :action-button-disabled="false"
        action-button="Build My Profile"
        @action-click="handleNavigation()">
        <template #main>
          <div data-testid="success-message" class="col-span-full">
            <p class="text-center text-lg text-tertiary dark:text-tertiary-dark">
              Now that your account is created it's time to get you some high quality leads!
            </p>
          </div>
          <div class="col-span-full">
            <p class="text-center text-lg text-tertiary dark:text-tertiary-dark">
              Lets start by getting your profile built.
            </p>
          </div>
          <div class="col-span-full">
            <p class="text-center text-lg text-tertiary dark:text-tertiary-dark">
              A complete and detailed profile will get you matched with more quality leads.
            </p>
          </div>
        </template>
      </CrossgridCard>
    </template>
  </CrossgridAuthLayout>
</template>

<script setup lang="ts">
import {onMounted, ref, Ref} from 'vue';
import {useRouter} from 'vue-router';

import {CROSSGRID_PAGE_URLS} from '@/constants/common';
import {MinimalUser} from '@/types/user';
import {getSession} from '@/utils/shared';

import CrossgridAuthLayout from '@/components/_layout/CrossgridAuthLayout.vue';
import CrossgridCard from '@/components/atoms/CrossgridCard.vue';

const userSession: Ref<MinimalUser | null> = ref(null);
const proListing: Ref<string> = ref('');

const router = useRouter();

const handleNavigation = () => {
  return router.push(CROSSGRID_PAGE_URLS.pro.profile.setup.one);
};

onMounted(() => {
  getSession().then(session => {
    userSession.value = session;
    proListing.value = userSession.value?.pro_listing || '';
  });
});
</script>
