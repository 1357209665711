<template>
  <CrossgridDashboardLayout sidebar-size="basis-1/3">
    <template #sidebar>
      <CrossgridSidebar :items="PRO_SIDEBAR_ITEMS" />
    </template>
    <template #main>
      <CrossgridCard
        title="Profile"
        description="View or update your profile information"
        action-button="Save"
        size="md:max-w-2xl"
        :cancel-button="null"
        :action-button-disabled="actionButtonDisabled"
        @action-click="updateProfile">
        <template #main>
          <div class="col-span-full sm:col-span-3">
            <div v-if="isLoading" class="flex justify-center gap-3 font-semibold text-secondary">
              <div
                class="h-5 w-5 animate-spin rounded-full border-2 border-solid border-brand border-t-transparent"></div>
              Getting image...
            </div>
            <div v-else>
              <CrossgridImageInput
                id="photo"
                v-model:file-value="formData.photo"
                :image_validation_rules="imageValidationRules"
                :errors="errors" />
            </div>
          </div>
          <div
            data-e2e="profile-form"
            class="col-span-full flex flex-col justify-start space-y-3 sm:col-span-3">
            <div>
              <CrossgridLabel id="business_name" label="Business Name" />
              <CrossgridInput
                id="name"
                v-model="formData.name"
                data-e2e="business-name"
                type="text"
                placeholder="Enter business name"
                :disabled="isLoading"
                :errors="errors" />
            </div>
            <div>
              <CrossgridLabel id="phone_number" label="Phone Number" />
              <CrossgridInput
                id="phone_number"
                v-model="formData.phone_number"
                data-e2e="phone-number"
                type="text"
                placeholder="Enter phone number"
                :disabled="isLoading"
                :errors="errors" />
            </div>
            <div>
              <CrossgridLabel id="email" label="Email" />
              <CrossgridInput
                id="email"
                v-model="formData.email"
                data-e2e="email"
                type="text"
                placeholder="Enter email"
                help-text="This is not the email you use to log in. This is the email customers can use to contact you."
                :disabled="isLoading"
                :errors="errors" />
            </div>
          </div>
          <div class="col-span-full">
            <CrossgridLabel id="description" label="Description" />
            <CrossgridInput
              id="description"
              v-model="formData.description"
              data-e2e="description"
              type="multiline"
              placeholder="Enter a description"
              rows="8"
              :disabled="isLoading"
              :errors="errors" />
          </div>
        </template>
      </CrossgridCard>
    </template>
  </CrossgridDashboardLayout>
</template>

<script lang="ts" setup>
import {computed, onMounted, reactive, Ref, ref, watch} from 'vue';
import equal from 'fast-deep-equal';

import {PRO_SIDEBAR_ITEMS} from '@/constants/pro-profile';
import {APIStandardError} from '@/types/network';
import {ProListing} from '@/types/pro-listings';
import {ImageValidationRules} from '@/types/pro-profile';
import {MinimalUser, UserProfile} from '@/types/user';
import {useHttp} from '@/utils/composables';
import {getSession} from '@/utils/shared';

import CrossgridDashboardLayout from '@/components/_layout/CrossgridDashboardLayout.vue';
import CrossgridCard from '@/components/atoms/CrossgridCard.vue';
import CrossgridImageInput from '@/components/atoms/CrossgridImageInput.vue';
import CrossgridInput from '@/components/atoms/CrossgridInput.vue';
import CrossgridLabel from '@/components/atoms/CrossgridLabel.vue';
import CrossgridSidebar from '@/components/organisms/CrossgridSidebar.vue';

const http = useHttp();
const errors: Ref<APIStandardError | undefined> = ref(undefined);
const imageValidationRules: Ref<ImageValidationRules> = ref({} as ImageValidationRules);
const formData = reactive<ProListing>({} as ProListing);
const profile: Ref<ProListing> = ref({} as ProListing);
const isSaved = ref(true);
const isLoading = ref(false);

const userSession: Ref<MinimalUser | null> = ref(null);

const getProfile = () => {
  isLoading.value = true;
  http
    .get(`pro/pro_listing/${userSession.value?.pro_listing}/`)
    .then(response => {
      const profileData = response.data;
      handleFileProcessing(profileData.photo)
        .then(file => {
          isLoading.value = false;
          setFormData(profileData, file);
          return (errors.value = undefined);
        })
        .catch(error => {
          isLoading.value = false;
          errors.value = error.response;
        });
    })
    .catch(error => {
      isLoading.value = false;
      errors.value = error.response?.data;
    });
};

const updateProfile = () => {
  http
    .post(`/pro/pro_listing/${userSession.value?.pro_listing}/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(() => {
      profile.value = {...formData, photo: formData.photo};
      isSaved.value = true;
      errors.value = undefined;
    })
    .catch(error => {
      errors.value = error.response?.data;
    });
};

watch(formData, () => {
  if (profile.value) {
    isSaved.value =
      equal(formData, profile.value) && equal(formData.photo?.name, profile.value.photo?.name);
  }
});

const getImageValidationRules = () => {
  http
    .get('/pro/pro_listing/image_validation_rules/')
    .then(response => {
      imageValidationRules.value = response.data;
    })
    .catch(error => {
      errors.value = error.response?.data;
    });
};

const handleFileProcessing = (url: string) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.send();
  return new Promise<File>(resolve => {
    xhr.onload = () => {
      const blob = xhr.response;
      const file = new File([blob], 'image.jpg', {type: 'image/jpeg'});
      resolve(file);
    };
  });
};

const setFormData = (data: UserProfile, file: File) => {
  Object.assign(formData, data);
  formData.photo = file;
  profile.value = {...formData, photo: file};
};

const actionButtonDisabled = computed(() => {
  return isSaved.value;
});

onMounted(() => {
  getSession().then(session => {
    userSession.value = session;
    getImageValidationRules();
    getProfile();
  });
});
</script>
