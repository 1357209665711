<template>
  <CrossgridDashboardLayout sidebar-size="basis-1/3">
    <template #sidebar>
      <CrossgridSidebar
        :items="PRO_SIDEBAR_ITEMS"
        :default-inbox="CROSSGRID_PAGE_URLS.pro.dashboard.messages.inbox" />
    </template>
    <template #main>
      <main class="flex h-[calc(100vh-160px)] w-full">
        <CrossgridSidebarCategories :categories="categories" />
        <RouterView
          :key="$route.name"
          :messages="filteredMessages"
          :route-name="CROSSGRID_PAGE_URLS.pro.dashboard.messages.chat"
          :inbox-name="CROSSGRID_PAGE_URLS.pro.dashboard.messages.inbox"
          thread-status="pending"
          :is-thread-initiator="true"
          :is-loading="isLoading"
          @search-query="searchMessages"
          @update-starred="updateStarredThread"
          @update-archived="updateArchivedThread"
          @order-by="getThreads" />
      </main>
    </template>
  </CrossgridDashboardLayout>
</template>

<script lang="ts" setup>
import {computed, onMounted, Ref, ref, watch} from 'vue';
import {CheckIcon, ClockIcon, InboxIcon, StarIcon, WrenchIcon} from '@heroicons/vue/20/solid';
import {RouterView, useRoute} from 'vue-router';

import {CROSSGRID_PAGE_URLS} from '@/constants/common';
import {PRO_SIDEBAR_ITEMS} from '@/constants/pro-profile';
import {MessageThread} from '@/types/communication';
import {SortParam} from '@/types/network';
import {SidebarCategory} from '@/types/ui-elements';
import {useHttp} from '@/utils/composables';
import {queryStringConstructor} from '@/utils/shared';

import CrossgridDashboardLayout from '@/components/_layout/CrossgridDashboardLayout.vue';
import CrossgridSidebar from '@/components/organisms/CrossgridSidebar.vue';
import CrossgridSidebarCategories from '@/components/organisms/CrossgridSidebarCategories.vue';

const route = useRoute();

const http = useHttp();

const searchQuery = ref('');

const messageThreads: Ref<MessageThread[]> = ref([]);
const isLoading = ref(false);

const categories: SidebarCategory[] = [
  {
    displayName: 'Inbox',
    routeName: CROSSGRID_PAGE_URLS.pro.dashboard.messages.inbox,
    count: 0,
    icon: InboxIcon,
    type: 1,
  },
  {
    displayName: 'Starred',
    routeName: CROSSGRID_PAGE_URLS.pro.dashboard.messages.starred,
    count: 0,
    icon: StarIcon,
    type: 1,
    filter: {starred: true},
  },
  {
    displayName: 'Pending',
    routeName: CROSSGRID_PAGE_URLS.pro.dashboard.messages.pending,
    count: 0,
    icon: ClockIcon,
    type: 1,
    filter: {job_status: 'pending'},
  },
  {
    displayName: 'Hired',
    routeName: CROSSGRID_PAGE_URLS.pro.dashboard.messages.hired,
    count: 0,
    icon: WrenchIcon,
    type: 1,
    filter: {job_status: 'hired'},
  },
  {
    displayName: 'Job Completed',
    routeName: CROSSGRID_PAGE_URLS.pro.dashboard.messages.job_completed,
    count: 0,
    icon: CheckIcon,
    type: 1,
    filter: {job_status: 'job_completed'},
  },
  {
    displayName: 'Not Hired',
    routeName: CROSSGRID_PAGE_URLS.pro.dashboard.messages.not_hired,
    count: 0,
    type: 2,
    filter: {job_status: 'not_hired'},
  },
  {
    displayName: 'Archived',
    routeName: CROSSGRID_PAGE_URLS.pro.dashboard.messages.archived,
    count: 0,
    type: 2,
    filter: {archived: true},
  },
];

const getThreads = (orderByValue: SortParam | undefined = undefined): Promise<void> => {
  isLoading.value = true;
  const categoryFilter = categories.find(category => category.routeName === route.name)?.filter;
  let queryString = '';

  if (categoryFilter) {
    queryString = queryStringConstructor(categoryFilter);
  }
  if (orderByValue) {
    queryString = queryStringConstructor({...categoryFilter, ...orderByValue});
  }

  return http
    .get(`/communication/thread/${queryString}`)
    .then(response => {
      messageThreads.value = response.data;
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const updateArchivedThread = (slug: string) => {
  return http.post(`/communication/${slug}/archive/`).then(() => {
    getThreads();
  });
};

const updateStarredThread = (slug: string) => {
  return http.post(`/communication/${slug}/star/`).then(() => {
    getThreads();
  });
};

const filteredMessages = computed(() => {
  let filtered = messageThreads.value;
  if (searchQuery.value) {
    const query = searchQuery.value.toLowerCase();
    filtered = filtered.filter(message => message.from_user.toLowerCase().includes(query));
  }
  return filtered;
});

const searchMessages = (query: string) => {
  searchQuery.value = query;
};

onMounted(() => {
  getThreads();
});

watch(
  () => route.name,
  newRouteName => {
    if (newRouteName) {
      getThreads();
    }
  }
);
</script>
