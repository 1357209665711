<template>
  <div class="flex h-screen w-full overflow-hidden">
    <div class="relative flex-1">
      <header
        class="flex justify-between bg-tertiary p-4 text-primary dark:bg-secondary-dark dark:text-primary-dark">
        <h1 class="text-2xl font-semibold">
          {{ headerName }}
        </h1>
        <div class="text-right">
          <button
            type="button"
            data-testid="back-button"
            class="w-full rounded-md bg-secondary px-4 py-2.5 text-sm font-semibold text-secondary shadow-sm ring-1 ring-inset ring-secondary hover:bg-secondary-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary dark:bg-secondary-dark dark:text-secondary-dark dark:ring-secondary-dark dark:hover:bg-secondary-dark-hover dark:focus-visible:outline-secondary-dark"
            @click="handleNavigation">
            Back to Inbox
          </button>
          <!-- <Menu as="div" class="relative inline-block text-left">
            <div>
              <MenuButton
                class="inline-flex w-full justify-center rounded-md bg-primary px-4 py-2 text-sm font-medium text-primary focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 dark:bg-primary-dark dark:text-primary-dark">
                {{ statusLabel }}
                <ChevronDownIcon
                  class="hover:text-violet-100 -mr-1 ml-2 h-5 w-5 text-tertiary dark:text-tertiary-dark"
                  aria-hidden="true" />
              </MenuButton>
            </div>

            <Transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0">
              <MenuItems
                class="absolute right-0 mt-2 w-56 origin-top-right rounded-md bg-primary text-secondary shadow-lg ring-1 ring-black/5 focus:outline-none dark:bg-tertiary-dark dark:text-secondary-dark">
                <div class="px-1 py-1">
                  <MenuItem v-slot="{active}">
                    <button :class="buttonClass(active)" @click="handleUpdateStatus('Pending')">
                      Pending
                    </button>
                  </MenuItem>
                  <MenuItem v-slot="{active}">
                    <button :class="buttonClass(active)" @click="handleUpdateStatus('Hired')">
                      Hired
                    </button>
                  </MenuItem>

                  <MenuItem v-slot="{active}">
                    <button
                      :class="buttonClass(active)"
                      @click="handleUpdateStatus('Job Completed')">
                      Job Completed
                    </button>
                  </MenuItem>
                  <MenuItem v-slot="{active}">
                    <button :class="buttonClass(active)" @click="handleUpdateStatus('Not Hired')">
                      Not Hired
                    </button>
                  </MenuItem>
                </div>
              </MenuItems>
            </Transition>
          </Menu> -->
        </div>
      </header>

      <div
        ref="messagesContainer"
        class="h-[calc(100%-296px)] overflow-y-auto bg-primary p-4 pb-36 dark:bg-primary-dark">
        <div v-if="isLoading" class="flex flex-col items-center justify-center py-10">
          <div
            class="flex items-center gap-3 font-semibold text-secondary dark:text-secondary-dark">
            <div
              class="h-5 w-5 animate-spin rounded-full border-2 border-solid border-brand border-t-transparent"></div>
            Loading messages...
          </div>
        </div>

        <div
          v-else-if="!isLoading && fetchedMessages.length < 1"
          class="flex flex-col items-center justify-center py-10">
          <ChatBubbleLeftRightIcon class="h-10 w-10 text-secondary dark:text-secondary-dark" />
          <p class="mt-2 text-sm text-secondary dark:text-secondary-dark">No messages available</p>
        </div>

        <div v-for="(message, index) in fetchedMessages" :key="index">
          <div v-if="message.sender_slug === userStore.user?.slug" class="mb-4 flex justify-end">
            <div class="flex max-w-[75%] flex-col items-end">
              <div
                class="flex w-full justify-between gap-10 text-sm text-secondary dark:text-secondary-dark">
                <div class="font-medium">You</div>
                <div class="text-xs">{{ formatTimestamp(message.updated_on) }}</div>
              </div>
              <div class="rounded-lg bg-brand-primary-dark p-3 text-primary-dark">
                <p class="break-words">{{ message.body }}</p>
              </div>
            </div>
            <div class="ml-2 flex h-9 w-9 items-center justify-center rounded-full">
              <img
                :src="`https://placehold.co/200x/b7a8ff/ffffff.svg?text=${getInitials(message.sender)}&font=Lato`"
                alt="My Avatar"
                class="h-8 w-8 rounded-full" />
            </div>
          </div>

          <div v-else class="mb-4 flex">
            <div class="mr-2 flex h-9 w-9 items-center justify-center rounded-full">
              <img
                :src="`https://placehold.co/200x/9f9f9f/ffffff.svg?text=${getInitials(message.sender)}&font=Lato`"
                alt="User Avatar"
                class="h-8 w-8 rounded-full" />
            </div>
            <div class="flex max-w-[75%] flex-col">
              <div
                class="flex w-full justify-between gap-10 text-sm text-secondary dark:text-secondary-dark">
                <div class="font-medium">{{ message.sender }}</div>
                <div class="text-xs">{{ formatTimestamp(message.updated_on) }}</div>
              </div>
              <div class="rounded-lg bg-tertiary p-3 dark:bg-secondary-dark dark:text-primary-dark">
                <p class="break-words">{{ message.body }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer
        class="absolute bottom-40 w-full border-t border-gray-300 bg-secondary p-4 dark:border-gray-700 dark:bg-secondary-dark">
        <form @submit.prevent="sendMessage">
          <div class="flex items-center gap-3">
            <div class="w-full">
              <CrossgridInput
                v-model="body"
                type="text"
                placeholder="Type a message..."
                :disabled="isLoading" />
            </div>
            <CrossgridIconButton
              type="submit"
              :icon="ArrowUpIcon"
              size="md"
              :disabled="isLoading" />
          </div>
        </form>
      </footer>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, onMounted, onUnmounted, ref} from 'vue';
import {useUserStore} from '@/stores/userStore';
import {ArrowUpIcon} from '@heroicons/vue/20/solid';
import {ChatBubbleLeftRightIcon} from '@heroicons/vue/24/solid';
import {useRoute, useRouter} from 'vue-router';

import {WEEKDAYS} from '@/constants/common';
import {Message} from '@/types/communication';
import {useHttp} from '@/utils/composables';

import CrossgridIconButton from '@/components/atoms/CrossgridIconButton.vue';
import CrossgridInput from '@/components/atoms/CrossgridInput.vue';

const route = useRoute();
const router = useRouter();
const http = useHttp();
const userStore = useUserStore();

type Props = {
  isThreadInitiator: boolean;
  routeName: string;
  inboxName: string;
};

const props = defineProps<Props>();

const fetchedMessages = ref<Message[]>([]);
const slug = route.params.slug as string;
const body = ref('');
const isLoading = ref(false);
const messagesContainer = ref<HTMLElement | null>(null);
let observer: MutationObserver | null = null;

onMounted(() => {
  // Create mutation observer to watch for changes to the messages container
  observer = new MutationObserver(() => {
    if (messagesContainer.value) {
      messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight;
    }
  });

  // Start observing the messages container for changes
  if (messagesContainer.value) {
    observer.observe(messagesContainer.value, {
      childList: true,
      subtree: true,
    });
  }

  getMessagesForThread();
});

onUnmounted(() => {
  // Clean up observer
  if (observer) {
    observer.disconnect();
  }
});

const handleNavigation = () => {
  router.push({name: props.inboxName});
};

const getMessagesForThread = () => {
  isLoading.value = true;
  http
    .get(`/communication/${slug}/message/`)
    .then(response => {
      fetchedMessages.value = response.data;
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const sendMessage = () => {
  if (body.value !== '') {
    http.post(`/communication/${slug}/message/`, {body: body.value}).then(() => {
      body.value = '';
      getMessagesForThread();
    });
  }
};

const getInitials = (name: string) => {
  const names = name.split(' ');
  return names.map(name => name[0]).join('');
};

const headerName = computed(() => {
  const firstMessage = fetchedMessages.value[0];
  return firstMessage?.is_thread_initiator ? firstMessage?.receiver : firstMessage?.sender;
});

const formatTimestamp = (timestamp: string) => {
  const date = new Date(timestamp);

  const weekday = WEEKDAYS[date.getDay()];

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;

  return `${weekday}, ${hours}:${minutes} ${ampm}`;
};
</script>
