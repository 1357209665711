<template>
  <div class="py-10 lg:px-16">
    <div :class="size" class="mx-auto w-full">
      <div class="bg-primary px-6 py-12 shadow dark:bg-primary-dark sm:rounded-lg sm:px-12">
        <img
          v-if="logo"
          class="mx-auto mb-6 h-12"
          :src="`${CROSSGRID_IMAGE_HOSTING_BASE_URL}/crossgrid-icon.svg`"
          alt="Crossgrid" />
        <h1
          v-if="title"
          class="mb-6 text-center text-3xl font-medium text-primary dark:text-primary-dark">
          <slot name="title">{{ title }}</slot>
        </h1>
        <slot v-if="customDescription" name="description"></slot>
        <p v-if="description" class="my-4 mb-6 text-center text-tertiary dark:text-tertiary-dark">
          {{ description }}
        </p>

        <component
          :is="mainContainerType"
          v-if="mainContent"
          class="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
          <CrossgridErrorMessage :errors="errors" />
          <CrossgridSuccessMessage :success-message="successMessage" />

          <slot name="main"></slot>

          <div class="col-span-full">
            <div
              v-if="actionButton || cancelButton"
              class="mt-3 flex flex-col justify-center gap-3">
              <button
                v-if="actionButton && !isLoading"
                :disabled="actionButtonDisabled"
                :data-testid="'action-button'"
                type="button"
                class="text-md w-full rounded-md bg-brand-solid px-4 py-2.5 font-semibold text-primary-fg shadow-sm ring-1 ring-inset ring-brand-solid hover:bg-brand-solid-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary disabled:cursor-not-allowed disabled:bg-disabled disabled:text-disabled disabled:ring-disabled-subtle dark:disabled:bg-disabled-dark dark:disabled:text-disabled-dark dark:disabled:ring-disabled-dark-subtle"
                @click="(e: Event) => emits('action-click', e)">
                {{ actionButton }}
              </button>
              <button
                v-else-if="actionButton"
                :data-testid="'action-button-loading'"
                type="button"
                disabled
                class="text-md flex w-full items-center justify-center gap-x-2.5 rounded-md bg-brand-solid px-4 py-2.5 font-semibold text-primary-fg shadow-sm ring-1 ring-inset ring-brand-solid focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary disabled:cursor-not-allowed">
                <div
                  class="h-5 w-5 animate-spin rounded-full border-2 border-solid border-fg border-t-transparent"></div>
                Loading...
              </button>
              <button
                v-if="secondaryButton"
                type="button"
                data-testid="secondary-button"
                class="text-md w-full rounded-md bg-secondary px-4 py-2.5 font-semibold text-secondary hover:bg-secondary-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary dark:bg-secondary-dark dark:text-secondary-dark dark:ring-secondary-dark dark:hover:bg-secondary-dark-hover dark:focus-visible:outline-secondary-dark"
                @click="(e: Event) => emits('secondary-click', e)">
                {{ secondaryButton }}
              </button>
              <button
                v-if="cancelButton"
                type="button"
                data-testid="cancel-button"
                class="text-md w-full rounded-md bg-secondary px-4 py-2.5 font-semibold text-secondary shadow-sm ring-1 ring-inset ring-secondary hover:bg-secondary-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary dark:bg-secondary-dark dark:text-secondary-dark dark:ring-secondary-dark dark:hover:bg-secondary-dark-hover dark:focus-visible:outline-secondary-dark"
                @click="(e: Event) => emits('cancel-click', e)">
                {{ cancelButton }}
              </button>
            </div>
          </div>
          <div v-if="signupFlow" class="col-span-full">
            <p class="text-center text-sm text-tertiary dark:text-tertiary-dark">
              Already have an account?
              <a
                :href="CROSSGRID_PAGE_URLS.allauth.login"
                class="font-semibold leading-6 text-brand-solid hover:text-brand-solid-hover dark:text-brand-solid dark:hover:text-brand-solid-dark-hover">
                Log in
              </a>
            </p>
          </div>
        </component>
      </div>
      <div v-if="supportBox" class="mt-14 text-center">
        <h2 class="text-lg font-semibold text-primary dark:text-primary-dark">Need help?</h2>
        <p class="mb-4 text-sm text-tertiary dark:text-tertiary-dark">Mon-Fri from 8am to 5pm.</p>
        <a
          class="font-semibold leading-6 text-brand-solid hover:text-brand-solid-hover dark:text-brand-solid dark:hover:text-brand-solid-dark-hover"
          :href="`tel:${CROSSGRID_SUPPORT.phone}`"
          >{{ CROSSGRID_SUPPORT.phone }}</a
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  CROSSGRID_IMAGE_HOSTING_BASE_URL,
  CROSSGRID_PAGE_URLS,
  CROSSGRID_SUPPORT,
} from '@/constants/common';
import {SuccessMessage} from '@/types/common';
import {APIStandardError} from '@/types/network';

import CrossgridErrorMessage from '@/components/atoms/CrossgridErrorMessage.vue';
import CrossgridSuccessMessage from '@/components/atoms/CrossgridSuccessMessage.vue';

type Props = {
  title?: string | null;
  size?: 'md:max-w-2xl' | 'sm:max-w-lg' | 'sm:max-w-md';
  errors?: APIStandardError;
  successMessage?: SuccessMessage;
  supportBox?: boolean;
  signupFlow?: boolean;
  logo?: boolean;
  mainContent?: boolean;
  description?: string | null;
  customDescription?: boolean;
  actionButton?: string | null;
  cancelButton?: string | null;
  secondaryButton?: string | null;
  actionButtonDisabled?: boolean;
  mainContainerType?: 'div' | 'form';
  isLoading?: boolean;
};

withDefaults(defineProps<Props>(), {
  title: 'Card title',
  size: 'sm:max-w-lg',
  errors: undefined,
  successMessage: undefined,
  supportBox: true,
  signupFlow: false,
  logo: false,
  mainContent: true,
  description: 'Your beautiful description here',
  customDescription: false,
  actionButton: 'Next',
  cancelButton: 'Back',
  secondaryButton: null,
  actionButtonDisabled: true,
  mainContainerType: 'div',
  isLoading: false,
});

const emits = defineEmits<{
  (e: 'action-click', value: Event): Event;
  (e: 'secondary-click', value: Event): Event;
  (e: 'cancel-click', value: Event): Event;
}>();
</script>
