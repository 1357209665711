<template>
  <CrossgridDashboardLayout sidebar-size="basis-1/3">
    <template #sidebar>
      <CrossgridSteps :steps="newSteps()" />
    </template>
    <template #main>
      <CrossgridCard
        title="Preferences"
        description="When and where are you able to serve your customers?"
        size="md:max-w-2xl"
        :action-button-disabled="false"
        :errors="errors"
        :is-loading="isLoading"
        @cancel-click="handleNavigation('back')"
        @action-click="handleNavigation('next')">
        <template #main>
          <CrossgridProAvailability />
        </template>
      </CrossgridCard>
    </template>
  </CrossgridDashboardLayout>
</template>

<script lang="ts" setup>
import {onMounted, Ref, ref} from 'vue';
import {useRouter} from 'vue-router';

import {CROSSGRID_PAGE_URLS} from '@/constants/common';
import {PRO_PROFILE_SETUP_STEPS} from '@/constants/pro-profile';
import {APIStandardError} from '@/types/network';
import {ProBusinessHours} from '@/types/pro-profile';
import {MinimalUser} from '@/types/user';
import {useHttp} from '@/utils/composables';
import {getSession} from '@/utils/shared';

import CrossgridDashboardLayout from '@/components/_layout/CrossgridDashboardLayout.vue';
import CrossgridCard from '@/components/atoms/CrossgridCard.vue';
import CrossgridSteps from '@/components/atoms/CrossgridSteps.vue';
import CrossgridProAvailability from '@/components/organisms/CrossgridProAvailability.vue';

const steps = PRO_PROFILE_SETUP_STEPS;

const router = useRouter();

const errors: Ref<APIStandardError | undefined> = ref(undefined);
const http = useHttp();

const userSession: Ref<MinimalUser | null> = ref(null);
const proListing: Ref<string> = ref('');
const isLoading = ref(false);

const newSteps = () => {
  for (const key in steps) {
    if (steps[key].name === 'About you') {
      steps[key].status = 'complete';
    } else if (steps[key].name === 'Preferences') {
      steps[key].status = 'current';
    }
  }
  return steps;
};

const handleNavigation = (action: string) => {
  if (action === 'back') {
    for (const key in steps) {
      if (steps[key].name === 'About you') {
        steps[key].status = 'current';
      } else if (steps[key].name === 'Preferences') {
        steps[key].status = 'upcoming';
      }
    }
    return router.push({
      name: CROSSGRID_PAGE_URLS.pro.profile.setup.one,
    });
  }
  if (action === 'next') {
    for (const key in steps) {
      if (steps[key].name === 'Preferences') {
        steps[key].status = 'complete';
      }
    }
    submitAvailability();
  }
};

const submitAvailability = () => {
  isLoading.value = true;
  const formattedHours: ProBusinessHours[] = JSON.parse(
    localStorage.getItem('pro_availability_changes') || '[]'
  );

  http
    .post(`/pro/pro_listing/${proListing.value}/availability/`, formattedHours)
    .then(() => {
      isLoading.value = false;
      localStorage.removeItem('pro_availability_changes');
      http.post(`/pro/pro_profile/setup_stage/${proListing.value}/`, {
        stage: '0',
      });
      router.push(CROSSGRID_PAGE_URLS.pro.dashboard.home);
      return;
    })
    .catch(error => {
      isLoading.value = false;
      errors.value = error.response?.data;
    });
};

onMounted(() => {
  getSession().then(session => {
    userSession.value = session;
    proListing.value = userSession.value?.pro_listing || '';
  });
});
</script>
