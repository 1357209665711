<template>
  <nav aria-label="Dashboard" data-e2e="dashboard-sidebar">
    <ul role="list" class="flex justify-evenly lg:flex-col">
      <li v-for="item in items" :key="item.routeName">
        <RouterLink
          :to="getRouteConfig(item)"
          :class="[
            isRouteActive(item)
              ? 'bg-tertiary text-primary dark:bg-secondary-dark dark:text-primary-dark'
              : 'hover:text-text hover:bg-secondary dark:text-secondary-dark dark:hover:bg-tertiary-dark dark:hover:text-primary-dark',
            'mt-2 flex items-center space-x-3 rounded-full p-4 text-sm leading-6 transition-colors duration-75 md:rounded-3xl md:px-6 md:py-3 lg:my-2 lg:ml-[23px] lg:mr-0 lg:mt-0 lg:rounded-r-none lg:pl-5 lg:pr-32',
          ]">
          <component :is="item.icon" class="h-5 w-5 shrink-0" aria-hidden="true" />
          <div class="hidden md:block">{{ item.displayName }}</div>
        </RouterLink>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import {RouterLink, useRoute} from 'vue-router';

import {SidebarItem} from '@/types/ui-elements';

type Props = {
  items: SidebarItem[];
  defaultInbox?: string;
};

const props = defineProps<Props>();
const route = useRoute();

const getRouteConfig = (item: SidebarItem) => {
  if (item.defaultRoute) {
    return {
      name: props.defaultInbox || item.defaultRoute,
      replace: true,
    };
  }
  return {
    name: item.routeName,
  };
};

const isRouteActive = (item: SidebarItem) => {
  if (item.defaultRoute) {
    return route.path?.includes('/dashboard/messages') ?? false;
  }
  return route.name === item.routeName;
};
</script>
