<template>
  <CrossgridAuthLayout>
    <template #main>
      <CrossgridCard
        title="Slot used"
        description=""
        :signup-flow="!userSession?.is_authenticated"
        :action-button-disabled="actionButtonDisabled"
        :errors="errors"
        @cancel-click="handleNavigation('back')"
        @action-click="handleNavigation('next')">
        <template #title>{{ title }}</template>
        <template #main>
          <div class="col-span-full">
            <CrossgridSearch
              v-model="selectedService"
              :pro-services="proServices"
              placeholder="Plumbing, painting, etc."
              @search-query="(query: string) => handleSearchQuery(query)" />

            <p id="business-description" class="mt-2 text-xs text-tertiary dark:text-tertiary-dark">
              {{ description }}
            </p>
          </div>
        </template>
      </CrossgridCard>
    </template>
  </CrossgridAuthLayout>
</template>

<script setup lang="ts">
import {computed, onBeforeMount, onMounted, ref} from 'vue';
import type {Ref} from 'vue';
import {useRouter} from 'vue-router';

import {CROSSGRID_PAGE_URLS} from '@/constants/common';
import {ProService, ProServiceSelectSavedProgress, SourceType} from '@/types/pro-services';
import {MinimalUser} from '@/types/user';
import {errors, searchProService} from '@/utils/pro-services-search';
import {getSession} from '@/utils/shared';

import CrossgridAuthLayout from '@/components/_layout/CrossgridAuthLayout.vue';
import CrossgridCard from '@/components/atoms/CrossgridCard.vue';
import CrossgridSearch from '@/components/molecules/CrossgridSearch.vue';

const selectedService: Ref<ProService | null> = ref(null);
const proServices: Ref<ProService[]> = ref([]);

const userSession: Ref<MinimalUser | null> = ref(null);

const router = useRouter();

const handleNavigation = (action: string) => {
  if (action === 'back' && source.value === 'user_job_search') {
    return router.push(CROSSGRID_PAGE_URLS.general.home);
  }

  if (action === 'back' && (source.value === 'pro_signup' || source.value === 'user_signup')) {
    return router.push(CROSSGRID_PAGE_URLS.general.signup);
  }

  if (!selectedService.value?.slug) {
    localStorage.setItem(
      'pro_service_select_progress',
      JSON.stringify({
        service_custom: selectedService.value,
      })
    );
    const navigateToUrls = {
      pro_signup: CROSSGRID_PAGE_URLS.pro.signup.step_one,
      user_signup: CROSSGRID_PAGE_URLS.user.signup.step_one,
      user_job_search: CROSSGRID_PAGE_URLS.user.job_search.step_two,
    };
    return router.push(navigateToUrls[source.value]);
  }

  if (selectedService.value) {
    const progress: ProServiceSelectSavedProgress = {
      step_one: {pro_service: selectedService.value.slug, source: source.value},
    };
    localStorage.setItem('pro_service_select_progress', JSON.stringify(progress));

    router.push({
      name: CROSSGRID_PAGE_URLS.pro_service.select.step_two,
      params: {slug: selectedService.value.slug, source: source.value},
    });
  }
};

const actionButtonDisabled = computed(() => {
  return selectedService.value === null;
});

const title = computed(() => {
  const text = {
    pro_signup: 'How do you help others?',
    user_signup: 'What job needs to get done?',
    user_job_search: 'What job needs to get done?',
  };
  return text[source.value];
});

const description = computed(() => {
  const text = {
    pro_signup: 'You can select additional related services in the next section',
    user_signup: 'You can select additional related services in the next section',
    user_job_search: 'You can select additional related services in the next section',
  };
  return text[source.value];
});

const source = computed(() => {
  return router.currentRoute.value.params.source as SourceType;
});

const handleSearchQuery = (searchQuery: string) => {
  if (searchQuery === '') selectedService.value = null;
  searchProService(searchQuery).then(services => (proServices.value = services));
};

onBeforeMount(() => {
  if (!source.value) return (window.location.href = '/404/');
  localStorage.removeItem('job_search_progress');
  localStorage.removeItem('pro_service_select_progress');
});

onMounted(() => {
  getSession().then(session => {
    userSession.value = session;

    if (!userSession.value?.is_authenticated && source.value === 'user_job_search') {
      return router.push({
        name: CROSSGRID_PAGE_URLS.pro_service.select.step_one,
        params: {source: 'user_signup'},
      });
    }
  });
});
</script>
