<template>
  <CrossgridAuthLayout>
    <template #main>
      <CrossgridCard
        title="🎉 Welcome to Crossgrid 🎉"
        description=""
        :cancel-button="null"
        :action-button-disabled="false"
        action-button="Add Job Details"
        @action-click="handleNavigation()">
        <template #main>
          <div data-testid="success-message" class="col-span-full">
            <p class="text-center text-lg text-tertiary dark:text-tertiary-dark">
              Now that your account is created, it's time to pick the right Pro for the job.
            </p>
          </div>
          <div class="col-span-full">
            <p class="text-center text-lg text-tertiary dark:text-tertiary-dark">
              You will pick the Pro you think is best for your dream job. (Or maybe just that job
              you've been dreading)
            </p>
          </div>
          <div class="col-span-full">
            <p class="text-center text-lg text-tertiary dark:text-tertiary-dark">
              Before picking your Pro, let's get some more details about the job.
            </p>
          </div>
        </template>
      </CrossgridCard>
    </template>
  </CrossgridAuthLayout>
</template>

<script setup lang="ts">
import {useRouter} from 'vue-router';

import {CROSSGRID_PAGE_URLS} from '@/constants/common';

import CrossgridAuthLayout from '@/components/_layout/CrossgridAuthLayout.vue';
import CrossgridCard from '@/components/atoms/CrossgridCard.vue';

const router = useRouter();

const handleNavigation = () => {
  return router.push(CROSSGRID_PAGE_URLS.user.job_search.step_one);
};
</script>
