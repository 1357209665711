<template>
  <CrossgridAuthLayout>
    <template #main>
      <CrossgridCard
        title="Slot used"
        :description="description"
        :signup-flow="!userSession?.is_authenticated"
        :action-button-disabled="actionButtonDisabled"
        :errors="errors"
        @cancel-click="handleNavigation('back')"
        @action-click="handleNavigation('next')">
        <template #title>{{ title }}</template>
        <template #main>
          <div class="col-span-full">
            <div class="mb-2">
              <button
                type="button"
                :data-testid="'checkbox-toggle'"
                class="text-md rounded-md py-2.5 font-semibold text-brand-solid hover:text-brand-solid-hover"
                @click="toggleCheckBoxes()">
                {{ checkedList.length > 1 ? 'Deselect All' : 'Select All' }}
              </button>
            </div>
            <div v-if="isLoading" class="flex justify-center gap-3 font-semibold text-secondary">
              <div
                class="h-5 w-5 animate-spin rounded-full border-2 border-solid border-brand border-t-transparent"></div>
              Getting services...
            </div>
            <fieldset v-else class="border-b border-t border-secondary dark:border-secondary-dark">
              <legend class="sr-only">Services</legend>
              <div class="divide-border-secondary dark:divide-border-secondary-dark divide-y">
                <div
                  v-for="(service, index) in proListingServicesOffered"
                  :key="index"
                  class="flex items-center py-2">
                  <div class="flex h-6 items-center">
                    <input
                      :id="service.name"
                      v-model="checkedList"
                      :tabindex="0"
                      :value="service.slug"
                      :data-testid="`pro-service-related-input-${index}`"
                      aria-describedby="pro-services-description"
                      name="pro_services"
                      type="checkbox"
                      class="h-6 w-6 cursor-pointer rounded border-primary bg-white text-brand-solid focus:ring-brand-solid dark:border-primary-dark dark:bg-primary-dark dark:text-brand-solid-dark dark:focus:ring-brand-solid-dark"
                      @keydown.enter.prevent="toggleCheckBox(service.slug)" />
                  </div>
                  <div class="text-md ml-3 leading-6">
                    <label
                      for="comments"
                      class="font-medium text-secondary dark:text-secondary-dark"
                      >{{ service.name }}</label
                    >
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </template>
      </CrossgridCard>
    </template>
  </CrossgridAuthLayout>
</template>

<script setup lang="ts">
import {computed, onBeforeMount, onMounted, ref, Ref} from 'vue';
import {useRouter} from 'vue-router';

import {CROSSGRID_PAGE_URLS} from '@/constants/common';
import {APIStandardError} from '@/types/network';
import {ProService, ProServiceSelectSavedProgress, SourceType} from '@/types/pro-services';
import {MinimalUser} from '@/types/user';
import {useHttp} from '@/utils/composables';
import {getProServiceSelectSavedProgress} from '@/utils/pro-services';
import {getSession} from '@/utils/shared';

import CrossgridAuthLayout from '@/components/_layout/CrossgridAuthLayout.vue';
import CrossgridCard from '@/components/atoms/CrossgridCard.vue';

const proListingServicesOffered: Ref<ProService[]> = ref([]);
const checkedList: Ref<string[]> = ref(['']);
const proServiceRole: Ref<string> = ref('');
const errors: Ref<APIStandardError | undefined> = ref(undefined);
const proServiceSelectProgress = ref<ProServiceSelectSavedProgress | null>(
  getProServiceSelectSavedProgress()
);

const isLoading: Ref<boolean> = ref(true);

const router = useRouter();
const http = useHttp();

const userSession: Ref<MinimalUser | null> = ref(null);

const successRoutes = {
  pro_signup: CROSSGRID_PAGE_URLS.pro.signup.step_one,
  user_signup: CROSSGRID_PAGE_URLS.user.signup.step_one,
  user_job_search: CROSSGRID_PAGE_URLS.user.job_search.step_one,
};

const handleNavigation = (action: string) => {
  if (action === 'back')
    return router.push({
      name: CROSSGRID_PAGE_URLS.pro_service.select.step_one,
      params: {source: source.value},
    });
  if (checkedList.value.length > 0) {
    if (proServiceSelectProgress.value) {
      proServiceSelectProgress.value.step_one.pro_service_role = proServiceRole.value;
      proServiceSelectProgress.value.step_two = checkedList.value;
      localStorage.setItem(
        'pro_service_select_progress',
        JSON.stringify(proServiceSelectProgress.value)
      );
      return router.push(successRoutes[source.value]);
    }
  }
};

const toggleCheckBoxes = () => {
  return checkedList.value.length > 1
    ? (checkedList.value = [])
    : (checkedList.value = proListingServicesOffered.value.map(service => service.slug));
};

const toggleCheckBox = (slug: string) => {
  if (checkedList.value.includes(slug)) {
    checkedList.value = checkedList.value.filter(item => item !== slug);
  } else {
    checkedList.value = [...checkedList.value, slug];
  }
};

const actionButtonDisabled = computed(() => {
  return checkedList.value.length < 1 || isLoading.value;
});

const searchProServiceRelatedServices = (progress: ProServiceSelectSavedProgress, slug: string) => {
  http
    .get(`/pro/pro_service/${slug}/related_services/`)
    .then(response => {
      isLoading.value = false;
      checkedList.value = progress?.step_two ?? response.data.pre_checked_list;
      proListingServicesOffered.value = response.data.items;
      proServiceRole.value = response.data.role;
    })
    .catch(error => {
      isLoading.value = false;
      if (error.response?.status == 404) return (window.location.href = '/404/');
      errors.value = error.response?.data;
    });
};

const title = computed(() => {
  const text = {
    pro_signup: 'Select the services you offer',
    user_signup: 'Select any services related to your needs',
    user_job_search: 'Select any services related to your needs',
  };
  return text[source.value];
});

const description = computed(() => {
  const text = {
    pro_signup: 'You will show up in search results and get jobs for all services you select.',
    user_signup: 'You will be matched with businesses who can provide the services you select.',
    user_job_search: 'You will be matched with businesses who can provide the services you select.',
  };
  return text[source.value];
});

const source = computed(() => {
  return router.currentRoute.value.params.source as SourceType;
});

onMounted(() => {
  if (proServiceSelectProgress.value) {
    searchProServiceRelatedServices(
      proServiceSelectProgress.value,
      router.currentRoute.value.params.slug as string
    );
  }

  getSession().then(session => {
    userSession.value = session;

    if (!userSession.value?.is_authenticated && source.value === 'user_job_search') {
      return router.push({
        name: CROSSGRID_PAGE_URLS.pro_service.select.step_two,
        params: {slug: router.currentRoute.value.params.slug, source: 'user_signup'},
      });
    }
  });
});

onBeforeMount(() => {
  getSession().then(session => (userSession.value = session));
  if (!source.value) return (window.location.href = '/404/');
  // Make sure that step 1 is done, otherwise redirect user back
  if (!proServiceSelectProgress.value?.step_one) {
    return router.push({
      name: CROSSGRID_PAGE_URLS.pro_service.select.step_one,
      params: {source: source.value},
    });
  }
});
</script>
